import React, { Component } from 'react';
import './header.css';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink
} from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';

class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
  render() {
    return (
        <header>
		<Navbar color="dark" dark expand="md">
            <NavbarBrand href="http://www.igrecway.com">IGRECWAY</NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <LinkContainer exact={true} to="/">
                        <NavLink>
                            Home
                        </NavLink>
                    </LinkContainer>
                    <LinkContainer to="/privacypolicy">
                        <NavLink>
                        Privacy Policy
                        </NavLink>
                    </LinkContainer>
                </Nav>
            </Collapse>
        </Navbar>
	</header>
    );
  }
}

export default Header;
