import React from 'react'

const PrivacyPolicy = () => (
    <div className="container">
        <div className="row">		
          <div className="col-md-12 col-xs-12 jumbotron description">
            <h1 className="h1-title">Privacy Policy</h1>
            <p>This Privacy Policy describes the information collected by Igrecway through the Igrecway Hangman Game mobile application.</p>

            <p> We do not ask for or collect personally identifiable information such as names or email addresses about the users of our mobile applications. We do not include any social network integration, or third party advertising.</p>
  
            <p>If you have any questions about our Privacy Policy please contact us at: <a href="mailto:igrecway@gmail.com">igrecway@gmail.com</a></p>
        </div>
    </div>
  </div>		
)

export default PrivacyPolicy