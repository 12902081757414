import React, { Component } from 'react';
import './home.css';
import appstoreBadge from './img/btn-apple-download.svg';
import appIcon from './img/appIcon.png';
import Screenshots from '../screenshots/screenshots.js';
import { Row
        , Container } from 'reactstrap';

class Home extends Component {

  render() {

    return (
        <div className="rounded m-4 pt5 text-light">
            <Container>
                <Row>
                    <div className="col-lg-7 col-md-7 order-md-1">
                        <h1 className="h1-title">Igrecway Hangman</h1>
                        <img src={appIcon} alt="Igrecway Hangman" className="img-rounded img-fluid mb-2"></img>
                        <p className="lead text-sm-justify">
                            Igrecway Hangman is a superb word game for iPhone and iPad.
                        </p>
                        <p className="text-center">
                            <a href="https://itunes.apple.com/us/app/igrecway-hangman/id554531726?ls=1&mt=8">
                                <img src={appstoreBadge} className="rounded" alt="Download"/>
                            </a>
                        </p>
                        <p className="lead text-sm-justify">
			                Hangman is a word guessing game which nearly every child has played at least once in their life. Now on your iPhone and iPad.
			                Excellent for adults and kids. Learn English - expand your vocabulary. 
                        </p>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <Screenshots />
                    </div>
                </Row>
            </Container>
        </div>
    );
  }
}

export default Home;
