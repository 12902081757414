import React from 'react'
import { Switch, Route  } from 'react-router-dom'
import PrivacyPolicy from '../privacy-policy/privacy-policy';
import Home from '../home/Home.js';

const Main = () => (
  <main className="flex-fill">
    <Switch>
      <Route exact path={process.env.PUBLIC_URL + '/'} component={Home}/>
      <Route path={process.env.PUBLIC_URL + '/index.html'} component={Home}/>
      <Route path={process.env.PUBLIC_URL + '/privacypolicy.html'} component={PrivacyPolicy}/>
      <Route path={process.env.PUBLIC_URL + '/privacypolicy'} component={PrivacyPolicy}/>
      <Route component={Home}/>
    </Switch>
  </main>
)

export default Main